<script setup lang="ts">
import { getButtonColor } from '../config/colors'

import Link from './Atom/Link.vue'

import type { MenuItem } from '../types'

interface Props {
  item: MenuItem
  isDropdownList?: boolean
  parentPath?: string
}

const props = withDefaults(defineProps<Props>(), {
  isDropdownList: false,
  parentPath: '',
})

const emit = defineEmits<{
  (_e: 'menu-click', _event: Event, _item: MenuItem): void
}>()

const styleStore = useStyleStore()

const hasColor = computed(() => (props.item && props.item.color ? true : false))

const asideMenuItemActiveStyle = computed(() => (hasColor.value ? '' : styleStore.asideMenuItemActiveStyle))

const isDropdownActive = ref(false)

const componentClass = computed(() => [
  props.isDropdownList ? 'py-3 px-6 text-sm' : 'py-3',
  hasColor.value
    ? getButtonColor(props.item.color!, false, true)
    : `${styleStore.asideMenuItemStyle} dark:text-nickel-300 dark:hover:text-pataya-core`,
])

const hasDropdown = computed(() => !!props.item.menu && props.item.menu.length > 0)

const menuClick = (event: Event) => {
  emit('menu-click', event, props.item)

  if (hasDropdown.value) {
    isDropdownActive.value = !isDropdownActive.value
  }
}

// Define the type for vSlot
interface VSlot {
  isExactActive: boolean
}
</script>

<template>
  <li>
    <Link
      v-slot="vSlot"
      :to="item.to"
      :href="item.href"
      :target="item.target"
      class="flex cursor-pointer w-full"
      :class="componentClass"
      @click="menuClick"
    >
      <BaseIcon
        v-if="item.icon"
        :icon="item.icon"
        class="flex-none"
        :class="[(vSlot as VSlot).isExactActive ? asideMenuItemActiveStyle : '']"
        size="sm"
      />
      <span
        class="line-clamp-1 grow text-ellipsis"
        :class="{ 'pr-12': !hasDropdown, [asideMenuItemActiveStyle]: (vSlot as VSlot).isExactActive }"
      >
        {{ item.label }}
      </span>
      <BaseIcon
        v-if="hasDropdown"
        :icon="isDropdownActive ? 'minus-solid' : 'plus-solid'"
        class="flex-none"
        :class="[(vSlot as VSlot).isExactActive ? asideMenuItemActiveStyle : '']"
        size="sm"
      />
    </Link>
    <AsideMenuList
      v-if="hasDropdown && item.menu && item.menu.length > 0"
      :menu="item.menu"
      :parent-path="parentPath"
      :class="[styleStore.asideMenuDropdownStyle, isDropdownActive ? 'block dark:bg-nickel-800/50' : 'hidden']"
      is-dropdown-list
    />
  </li>
</template>
