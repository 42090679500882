import type { MenuItem } from '../types'

const menuAside: MenuItem[] = [
  {
    to: '/zone/',
    icon: 'desktop-solid',
    label: 'Dashboard',
  },
  // {
  //   to: "/demo/tables",
  //   label: "Tables",
  //   icon: 'table-solid',
  // },
  // {
  //   to: "/demo/forms",
  //   label: "Forms",
  //   icon: 'edit-solid',
  // },
  // {
  //   to: "/demo/ui",
  //   label: "UI",
  //   icon: 'tv-solid',
  // },
  // {
  //   to: "/demo/responsive",
  //   label: "Responsive",
  //   icon: 'mobile-alt-solid',
  // },
  // {
  //   to: "/demo/style",
  //   label: "Styles",
  //   icon: 'palette-solid',
  // },
  // {
  //   to: "/demo/profile",
  //   label: "Profile",
  //   icon: 'user-circle-solid',
  // },
  // {
  //   to: "/auth",
  //   label: "Login",
  //   icon: 'lock-solid',
  // },
  // {
  //   to: "/error",
  //   label: "Error",
  //   icon: 'exclamation-circle-solid',
  // },
  // {
  //   label: "Dropdown",
  //   icon: 'list-solid',
  //   menu: [
  //     {
  //       label: "Item One",
  //     },
  //     {
  //       label: "Item Two",
  //     },
  //   ],
  // },
]

export default menuAside
