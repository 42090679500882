<script setup lang="ts">
import type { MenuItem, MenuClickEmit, AsideMenuCloseEmit } from '../types'

interface Props {
  menu: MenuItem[]
  isAsideMobileExpanded?: boolean
  isAsideLgActive?: boolean
}

withDefaults(defineProps<Props>(), {
  isAsideMobileExpanded: false,
  isAsideLgActive: true,
})

const emit = defineEmits<MenuClickEmit & AsideMenuCloseEmit>()

const menuClick = (event: Event, item: MenuItem) => {
  emit('menu-click', event, item)
}

const asideLgCloseClick = (event: Event) => {
  emit('aside-lg-close-click', event)
}
</script>

<template>
  <AsideMenuLayer
    :menu="menu"
    :class="[isAsideMobileExpanded ? 'left-0' : '-left-72 lg:left-0', { 'lg:hidden xl:flex': !isAsideLgActive }]"
    @menu-click="menuClick"
    @aside-lg-close-click="asideLgCloseClick"
  />
  <OverlayLayer v-show="isAsideLgActive" z-index="z-30" @overlay-click="asideLgCloseClick" />
</template>
