<script setup>
import menuAside from '../config/menuAside'

const { signOut, getSession } = useAuth()

const { user } = await getSession()

const mainStore = useMainStore()
mainStore.setUser(user)

const activityStore = useActivityStore()

const layoutAsidePadding = 'xl:pl-72'

const styleStore = useStyleStore()

const router = useRouter()

const isAsideMobileExpanded = ref(false)
const isAsideLgActive = ref(false)

router.beforeEach(() => {
  isAsideMobileExpanded.value = false
  isAsideLgActive.value = false
})

const menuClick = async (_event, item) => {
  if (item.isToggleLightDark) {
    styleStore.setDarkMode()
  }

  if (item.isLogout) {
    await signOut()
    router.push('/')
  }
}
const darkMode = computed(() => styleStore.darkMode)
</script>

<template>
  <div
    :class="{
      'dark': darkMode,
      'overflow-hidden lg:overflow-visible': isAsideMobileExpanded,
    }"
  >
    <div
      :class="[layoutAsidePadding, { 'ml-72 lg:ml-0': isAsideMobileExpanded }]"
      class="bg-pumice transition-position dark:bg-nickel-800 dark:text-nickel-100 min-h-screen w-screen pt-14 lg:w-auto"
    >
      <Header
        :is-aside-mobile-expanded="isAsideMobileExpanded"
        :is-aside-lg-active="isAsideLgActive"
        @update:is-aside-mobile-expanded="isAsideMobileExpanded = $event"
        @update:is-aside-lg-active="isAsideLgActive = $event"
      />
      <ClientOnly>
        <AsideMenu
          :is-aside-mobile-expanded="isAsideMobileExpanded"
          :is-aside-lg-active="isAsideLgActive"
          :menu="[...menuAside, ...activityStore.menu]"
          @menu-click="menuClick"
          @aside-lg-close-click="isAsideLgActive = false"
        />
      </ClientOnly>
      <div class="prose max-w-none px-4">
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</template>
