<script setup lang="ts">
import type { MenuItem, MenuClickEmit, AsideMenuCloseEmit } from '../types'

defineProps<{
  menu: MenuItem[]
}>()

const emit = defineEmits<MenuClickEmit & AsideMenuCloseEmit>()

const styleStore = useStyleStore()

const logoutItem = computed<MenuItem>(() => ({
  label: 'Logout',
  icon: 'sign-out-solid',
  color: 'info',
  isLogout: true,
}))

const menuClick = (event: Event, item: MenuItem) => {
  emit('menu-click', event, item)
}

const asideLgCloseClick = (event: Event) => {
  emit('aside-lg-close-click', event)
}
</script>

<template>
  <aside
    id="aside"
    class="fixed top-0 z-40 flex h-screen w-72 max-w-screen-sm overflow-hidden transition-position lg:py-2 lg:pl-2"
  >
    <div :class="styleStore.asideStyle" class="flex flex-1 flex-col overflow-hidden dark:bg-nickel-900 lg:rounded-2xl">
      <div
        :class="styleStore.asideBrandStyle"
        class="flex h-14 flex-row items-center justify-between dark:bg-nickel-900"
      >
        <div class="flex-1 text-center lg:pl-6 lg:text-left xl:pl-0 xl:text-center">
          <AtomLogo class="inline-flex" type="horizontal" />
        </div>
        <button class="hidden p-3 lg:inline-block xl:hidden" @click.prevent="asideLgCloseClick">
          <BaseIcon icon="close-solid" size="2xs" />
        </button>
      </div>
      <div
        :class="styleStore.darkMode ? 'aside-scrollbars-[slate]' : styleStore.asideScrollbarsStyle"
        class="flex-1 overflow-y-auto overflow-x-hidden"
      >
        <AsideMenuList :menu="menu" @menu-click="menuClick" />
      </div>

      <ul>
        <AsideMenuItem :item="logoutItem" @menu-click="menuClick" />
      </ul>
    </div>
  </aside>
</template>
