<script setup lang="ts">
import type { MenuItem, MenuClickEmit } from '../types'

interface Props {
  isDropdownList?: boolean
  menu: MenuItem[]
  parentPath?: string
}

withDefaults(defineProps<Props>(), {
  isDropdownList: false,
  parentPath: '',
})

const emit = defineEmits<MenuClickEmit>()

const menuClick = (event: Event, item: MenuItem) => {
  emit('menu-click', event, item)
}
</script>

<template>
  <ul v-if="menu && menu.length > 0">
    <AsideMenuItem
      v-for="(item, index) in menu"
      :key="`aside-menu-item${parentPath}-${index}`"
      :item="item"
      :is-dropdown-list="isDropdownList"
      :parent-path="`${parentPath}-${index}`"
      @menu-click="menuClick"
    />
  </ul>
</template>
